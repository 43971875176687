import React, { useState } from "react";
import { ArrowRight } from 'react-bootstrap-icons'

function PreetiReadMore() {
    const [isShowMore, setIsShowMore] = useState(false);

    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };

    return (
        <div className="ReadMoreSection">
            <h4 className='m-0'>Preeti Developers</h4>
            
            <p className="mb-0">
                <p className="AboutExpoPara">Driven by a passion for real estate and a zeal for innovation, Preeti Developers is a name synonymous with quality and excellence. We are a team of highly motivated professionals whose experience spans Real Estate Development, Agro-Farming Technology, Landscape Development, Development of Gated Communities, Information technology, Finance, and more. With our zest for perfection, we have garnered the accolade of being a renowned real estate developer in South India</p>
                <p className="AboutExpoPara">Over the last 15 years, we have invested in and developed projects of all calibers bringing smiles to many a face by delivering dream homes and high-yielding investments and plots.</p>


            </p>

            {isShowMore && (
                
                 <p className="mb-0">
                    <h6>OUR MISSION</h6>
                <p className="AboutExpoPara">To Deliver the Best Value And Exceptional Results To Our Clients.
</p>
                <h6>OUR VISION</h6>
                <p className="AboutExpoPara">To Be The Go-To Real Estate Developers In South India</p>

                <h6>OUR VALUES</h6>
                <p className="AboutExpoPara">We operate on commitment, excellence, and innovation.
</p>


            </p>
            )}

            <p className="m-0 AboutExpoButton" onClick={toggleReadMoreLess}>{isShowMore ? "Read Less" : "Read More"} <ArrowRight /></p>
        </div>
    );
}

export default PreetiReadMore;